import React, { lazy, useEffect, useState } from 'react';
import { Routes, Route, Link, useNavigate, useLocation, Navigate, Outlet } from 'react-router-dom';
import RequireAuth from '../RequireAuth';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// system routing
const ProfileSettings = Loadable(lazy(() => import('views/system/ProfileSettings')));
const ChangePassword = Loadable(lazy(() => import('views/system/ChangePassword')));
const ReportSwitchboard = Loadable(lazy(() => import('views/system/ReportSwitchboard')));

// master routing
const AreaList = Loadable(lazy(() => import('views/master/area/AreaList')));
const AreaForm = Loadable(lazy(() => import('views/master/area/AreaForm')));
const AccountList = Loadable(lazy(() => import('views/master/account/AccountList')));
const AccountForm = Loadable(lazy(() => import('views/master/account/AccountForm')));
const DepartemenList = Loadable(lazy(() => import('views/master/departemen/DepartemenList')));
const DepartemenForm = Loadable(lazy(() => import('views/master/departemen/DepartemenForm')));
const RasaList = Loadable(lazy(() => import('views/master/rasa/RasaList')));
const RasaForm = Loadable(lazy(() => import('views/master/rasa/RasaForm')));
const BrandList = Loadable(lazy(() => import('views/master/brand/BrandList')));
const BrandForm = Loadable(lazy(() => import('views/master/brand/BrandForm')));
const SubBrandList = Loadable(lazy(() => import('views/master/sub-brand/SubBrandList')));
const SubBrandForm = Loadable(lazy(() => import('views/master/sub-brand/SubBrandForm')));
const KategoriUkuranList = Loadable(lazy(() => import('views/master/kategori-ukuran/KategoriUkuranList')));
const KategoriUkuranForm = Loadable(lazy(() => import('views/master/kategori-ukuran/KategoriUkuranForm')));
const UkuranList = Loadable(lazy(() => import('views/master/ukuran/UkuranList')));
const UkuranForm = Loadable(lazy(() => import('views/master/ukuran/UkuranForm')));
const ProdukList = Loadable(lazy(() => import('views/master/produk/ProdukList')));
const ProdukForm = Loadable(lazy(() => import('views/master/produk/ProdukForm')));

const BackendAccessList = Loadable(lazy(() => import('views/access/backend-access/BackendAccessList')));
const BackendAccessForm = Loadable(lazy(() => import('views/access/backend-access/BackendAccessForm')));

const KategoriHargaList = Loadable(lazy(() => import('views/master/kategori-harga/KategoriHargaList')));
const KategoriHargaForm = Loadable(lazy(() => import('views/master/kategori-harga/KategoriHargaForm')));

const KategoriPromoList = Loadable(lazy(() => import('views/budget/kategori-promo/KategoriPromoList')));
const KategoriPromoForm = Loadable(lazy(() => import('views/budget/kategori-promo/KategoriPromoForm')));

const PromoPlanList = Loadable(lazy(() => import('views/budget/promo-plan/PromoPlanList')));
const PromoPlanForm = Loadable(lazy(() => import('views/budget/promo-plan/PromoPlanForm')));

const CostCenterTree = Loadable(lazy(() => import('views/access/cost-center/CostCenterTree')));
const CostCenterForm = Loadable(lazy(() => import('views/access/cost-center/CostCenterForm')));

const ProposalPersetujuanList = Loadable(lazy(() => import('views/proposal/proposal-persetujuan/ProposalPersetujuanList')));

const ProposalList = Loadable(lazy(() => import('views/proposal/proposal/ProposalList')));
const ProposalForm = Loadable(lazy(() => import('views/proposal/proposal/ProposalForm')));
const ProposalView = Loadable(lazy(() => import('views/proposal/proposal/ProposalView')));
const ProposalKlaimForm = Loadable(lazy(() => import('views/proposal/proposal/ProposalKlaimForm')));

const ProposalArchiveList = Loadable(lazy(() => import('views/proposal/proposal-archive/ProposalArchiveList')));

const ProposalLogList = Loadable(lazy(() => import('views/proposal/proposal-log/ProposalLogList')));

const ProposalPostPromoList = Loadable(lazy(() => import('views/proposal/proposal-post-promo/ProposalPostPromoList')));
const ProposalPostPromoForm = Loadable(lazy(() => import('views/proposal/proposal-post-promo/ProposalPostPromoForm')));
const ProposalPostPromoView = Loadable(lazy(() => import('views/proposal/proposal-post-promo/ProposalPostPromoView')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    element: (
        <RequireAuth>
            <MainLayout />
        </RequireAuth>
    ),
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard',
            element: <DashboardDefault />
        },
        {
            path: '/system/profile-settings',
            element: <ProfileSettings />
        },
        {
            path: '/system/change-password',
            element: <ChangePassword />
        },
        {
            path: '/master/area/list',
            element: <AreaList />
        },
        {
            path: '/master/area/add',
            element: <AreaForm />
        },
        {
            path: '/master/area/view/:area_id',
            element: <AreaForm />
        },
        {
            path: '/master/account/list',
            element: <AccountList />
        },
        {
            path: '/master/account/add',
            element: <AccountForm />
        },
        {
            path: '/master/account/view/:account_id',
            element: <AccountForm />
        },
        {
            path: '/master/departemen/list',
            element: <DepartemenList />
        },
        {
            path: '/master/departemen/add',
            element: <DepartemenForm />
        },
        {
            path: '/master/departemen/view/:departemen_id',
            element: <DepartemenForm />
        },
        {
            path: '/master/brand/list',
            element: <BrandList />
        },
        {
            path: '/master/brand/add',
            element: <BrandForm />
        },
        {
            path: '/master/brand/view/:brand_id',
            element: <BrandForm />
        },

        {
            path: '/master/sub-brand/list',
            element: <SubBrandList />
        },
        {
            path: '/master/sub-brand/add',
            element: <SubBrandForm />
        },
        {
            path: '/master/sub-brand/view/:sub_brand_id',
            element: <SubBrandForm />
        },

        {
            path: '/master/kategori-ukuran/list',
            element: <KategoriUkuranList />
        },
        {
            path: '/master/kategori-ukuran/add',
            element: <KategoriUkuranForm />
        },
        {
            path: '/master/kategori-ukuran/view/:kategori_ukuran_id',
            element: <KategoriUkuranForm />
        },
        {
            path: '/master/ukuran/list',
            element: <UkuranList />
        },
        {
            path: '/master/ukuran/add',
            element: <UkuranForm />
        },
        {
            path: '/master/ukuran/view/:ukuran_id',
            element: <UkuranForm />
        },

        {
            path: '/master/rasa/list',
            element: <RasaList />
        },
        {
            path: '/master/rasa/add',
            element: <RasaForm />
        },
        {
            path: '/master/rasa/view/:rasa_id',
            element: <RasaForm />
        },
        {
            path: '/master/produk/view/:produk_id',
            element: <ProdukForm />
        },

        {
            path: '/master/produk/list',
            element: <ProdukList />
        },
        {
            path: '/master/produk/add',
            element: <ProdukForm />
        },
        {
            path: '/master/produk/view/:produk_id',
            element: <ProdukForm />
        },

        {
            path: '/access/backend-access/list',
            element: <BackendAccessList />
        },
        {
            path: '/access/backend-access/add',
            element: <BackendAccessForm />
        },
        {
            path: '/access/backend-access/view/:backend_access_id',
            element: <BackendAccessForm />
        },
        {
            path: '/master/kategori-harga/list',
            element: <KategoriHargaList />
        },
        {
            path: '/master/kategori-harga/add',
            element: <KategoriHargaForm />
        },
        {
            path: '/master/kategori-harga/view/:kategori_harga_id',
            element: <KategoriHargaForm />
        },
        {
            path: '/budget/kategori-promo/list',
            element: <KategoriPromoList />
        },
        {
            path: '/budget/kategori-promo/add',
            element: <KategoriPromoForm />
        },
        {
            path: '/budget/kategori-promo/view/:kategori_promo_id',
            element: <KategoriPromoForm />
        },

        {
            path: '/budget/promo-plan/list',
            element: <PromoPlanList />
        },
        {
            path: '/budget/promo-plan/add',
            element: <PromoPlanForm />
        },
        {
            path: '/budget/promo-plan/view/:kategori_promo_id',
            element: <PromoPlanForm />
        },

        {
            path: '/access/cost-center/tree',
            element: <CostCenterTree />
        },
        {
            path: '/access/cost-center/tree/:cost_center_id',
            element: <CostCenterTree />
        },
        {
            path: '/access/cost-center/add',
            element: <CostCenterForm />
        },
        {
            path: '/access/cost-center/add/:head',
            element: <CostCenterForm />
        },
        {
            path: '/access/cost-center/view/:cost_center_id',
            element: <CostCenterForm />
        },
        {
            path: '/proposal/proposal-persetujuan/list',
            element: <ProposalPersetujuanList />
        },
        {
            path: '/proposal/proposal/list',
            element: <ProposalList />
        },
        {
            path: '/proposal/proposal/add',
            element: <ProposalForm />
        },
        {
            path: '/proposal/proposal/edit/:proposal_id',
            element: <ProposalForm />
        },
        {
            path: '/proposal/proposal/view/:proposal_id',
            element: <ProposalView />
        },
        {
            path: '/proposal/proposal/view/:proposal_id/:referer',
            element: <ProposalView />
        },
        {
            path: '/proposal/:proposal_id/proposal-klaim',
            element: <ProposalKlaimForm />
        },
        {
            path: '/proposal/:proposal_id/proposal-klaim/:referer',
            element: <ProposalKlaimForm />
        },
        {
            path: '/proposal/proposal-archive/list',
            element: <ProposalArchiveList />
        },
        {
            path: '/proposal/proposal-log/list',
            element: <ProposalLogList />
        },
        /*
        {
            path: '/proposal/proposal-post-promo/view/:proposal_post_promo_id/:referer',
            element: <ProposalPostPromoView />
        },
        */
        {
            path: '/proposal/proposal-post-promo/list',
            element: <ProposalPostPromoList />
        },
        {
            path: '/proposal/proposal-post-promo/add/:proposal_id',
            element: <ProposalPostPromoForm />
        },
        {
            path: '/proposal/proposal-post-promo/edit/:proposal_post_promo_id',
            element: <ProposalPostPromoForm />
        },
        {
            path: '/proposal/proposal-post-promo/view/:proposal_post_promo_id',
            element: <ProposalPostPromoView />
        },

        {
            path: '/system/report-switchboard',
            element: <ReportSwitchboard />
        },

        {
            path: '/utils/util-typography',
            element: <UtilsTypography />
        },
        {
            path: '/utils/util-color',
            element: <UtilsColor />
        },
        {
            path: '/utils/util-shadow',
            element: <UtilsShadow />
        },
        {
            path: '/icons/tabler-icons',
            element: <UtilsTablerIcons />
        },
        {
            path: '/icons/material-icons',
            element: <UtilsMaterialIcons />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        }
    ]
};

export default MainRoutes;
