// assets
import { IconKey, IconDatabase, IconShoppingCart, IconBusinessplan } from '@tabler/icons';

// constant
const icons = {
    IconKey,
    IconDatabase,
    IconShoppingCart,
    IconBusinessplan
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const admin = {
    id: 'admin',
    title: 'Administration',
    caption: 'Pengaturan Sistem',
    type: 'group',
    roles: ['ADMIN', 'FINANCE', 'ACCOUNTING'],
    children: [
        {
            id: 'access',
            title: 'Access',
            type: 'collapse',
            icon: icons.IconKey,
            roles: ['ADMIN'],
            children: [
                {
                    id: 'departemen',
                    title: 'Departemen',
                    type: 'item',
                    url: '/master/departemen/list',
                    target: false
                },
                {
                    id: 'user',
                    title: 'User',
                    type: 'item',
                    url: '/access/backend-access/list',
                    target: false
                },
                {
                    id: 'cost-center',
                    title: 'Cost Center',
                    type: 'item',
                    url: '/access/cost-center/tree',
                    target: false
                }
            ]
        },
        {
            id: 'master',
            title: 'Master Data',
            type: 'collapse',
            icon: icons.IconDatabase,
            roles: ['ADMIN'],
            children: [
                {
                    id: 'produk',
                    title: 'Produk',
                    type: 'item',
                    url: '/master/produk/list'
                },
                {
                    id: 'rasa',
                    title: 'Rasa',
                    type: 'item',
                    url: '/master/rasa/list'
                },
                {
                    id: 'kategori-ukuran',
                    title: 'Kategori Ukuran',
                    type: 'item',
                    url: '/master/kategori-ukuran/list'
                },
                {
                    id: 'ukuran',
                    title: 'Ukuran',
                    type: 'item',
                    url: '/master/ukuran/list'
                },
                {
                    id: 'brand',
                    title: 'Brand',
                    type: 'item',
                    url: '/master/brand/list'
                },
                {
                    id: 'sub-brand',
                    title: 'Sub Brand',
                    type: 'item',
                    url: '/master/sub-brand/list'
                },
                {
                    id: 'kategori-harga',
                    title: 'Kategori Harga',
                    type: 'item',
                    url: '/master/kategori-harga/list'
                }
            ]
        },
        {
            id: 'marketing',
            title: 'Marketing Setup',
            type: 'collapse',
            icon: icons.IconShoppingCart,
            roles: ['ADMIN', 'FINANCE', 'ACCOUNTING'],
            children: [
                {
                    id: 'customer',
                    title: 'Account',
                    type: 'item',
                    url: '/master/account/list'
                },
                {
                    id: 'area',
                    title: 'Area',
                    type: 'item',
                    url: '/master/area/list'
                }
            ]
        },
        {
            id: 'budgeting',
            title: 'Budgeting Setup',
            type: 'collapse',
            icon: icons.IconBusinessplan,
            roles: ['ADMIN', 'FINANCE', 'ACCOUNTING'],
            children: [
                {
                    id: 'promo-plan',
                    title: 'Promo Plan & Trading Term',
                    type: 'item',
                    url: '/budget/promo-plan/list'
                },
                {
                    id: 'kategori-promo',
                    title: 'Kategori Promo',
                    type: 'item',
                    url: '/budget/kategori-promo/list'
                }
            ]
        }
    ]
};

export default admin;
