import axios from 'axios';

const { REACT_APP_CORE_SOA_URL, REACT_APP_SERVICE_URL } = process.env;

// Authentication
export const loginWithEmail = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/auth_email/session`, params);
export const loginWithPhone = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/auth_phone/session`, params);
export const registerWithEmail = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/auth_email`, params);
export const activateEmail = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/auth_email/${params.token}/activate`, params);
export const postRegisterPhone = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/auth_phone`, params);
export const postOtp = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/auth_phone/${params.no_handphone}/${params.action}`, params);
export const postResetPassword = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/auth_email/reset_password`, params);
export const postConfirmResetPassword = (params) =>
    axios.post(`${REACT_APP_CORE_SOA_URL}/v1/auth_email/${params.token}/confirm_reset_password`, params);
export const postExchangeJwt = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/exchange_jwt`, params);

// Header
export const getHeaderBuyer = (params) => axios.get(`${REACT_APP_SERVICE_URL}/v1/composites/header`);
export const getHeaderSeller = (params) => axios.get(`${REACT_APP_SERVICE_URL}/v1/composites/toko/${params.toko_id}/header`);

// Dashboard
export const getDashboard = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/dashboard/standard`, { params });
export const getChartProposalSummary = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/chart/proposal_summary`, { params });
export const getNotifikasi = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/notifikasi`, { params });
export const putNotifikasi = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/notifikasi`, params);

// Profile
export const getProfileList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/profile`, { params });
export const getProfile = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/profile/${params}`);
export const putProfile = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/profile/${params.profile_id}`, params);
export const putAvatarProfile = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/profile/me/image_avatar`, params);
export const getDashboardProfile = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/composites/profile/me/dashboard`, params);

// Auth Email
export const putAuthEmail = (params) =>
    axios.put(`${REACT_APP_CORE_SOA_URL}/v1/profile/${params.profile_id}/auth_email/${params.auth_email_id}`, params);

export const postFbProfile = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/profile/me/auth_facebook`, params);
export const deleteFbProfile = (params) =>
    axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/profile/me/auth_facebook/${params.auth_facebook_id}`, params);
export const postGoogleProfile = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/profile/me/auth_google`, params);
export const deleteGoogleProfile = (params) =>
    axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/profile/me/auth_google/${params.auth_google_id}`, params);
export const postAppleProfile = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/profile/me/auth_apple`, params);
export const deleteAppleProfile = (params) =>
    axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/profile/me/auth_apple/${params.auth_apple_id}`, params);

// Backend Access
export const getBackendAccessList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/backend_access`, { params });
export const getBackendAccess = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/backend_access/${params}`);
export const postBackendAccess = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/backend_access`, params);
export const putBackendAccess = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/backend_access/${params.backend_access_id}`, params);
export const deleteBackendAccess = (backendAccessId) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/backend_access/${backendAccessId}`);

// Area
export const getAreaList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/area`, { params });
export const getArea = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/area/${params}`);
export const postArea = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/area`, params);
export const putArea = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/area/${params.area_id}`, params);
export const deleteArea = (params) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/area/${params.area_id}`);

// Wilayah
export const getWilayahList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/wilayah`, { params });
export const getWilayah = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/wilayah/${params}`);
export const postWilayah = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/wilayah`, params);
export const putWilayah = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/wilayah/${params.wilayah_id}`, params);
export const deleteWilayah = (params) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/wilayah/${params.wilayah_id}`);

// Brand
export const getBrandList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/brand`, { params });
export const getBrand = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/brand/${params}`);
export const postBrand = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/brand`, params);
export const putBrand = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/brand/${params.brand_id}`, params);
export const deleteBrand = (params) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/brand/${params.brand_id}`);

// Sub Brand
export const getSubBrandList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/sub_brand`, { params });
export const getSubBrand = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/sub_brand/${params}`);
export const postSubBrand = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/sub_brand`, params);
export const putSubBrand = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/sub_brand/${params.sub_brand_id}`, params);
export const deleteSubBrand = (params) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/sub_brand/${params.sub_brand_id}`);

// Rasa
export const getRasaList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/rasa`, { params });
export const getRasa = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/rasa/${params}`);
export const postRasa = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/rasa`, params);
export const putRasa = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/rasa/${params.rasa_id}`, params);
export const deleteRasa = (params) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/rasa/${params.rasa_id}`);

// Kategori Ukuran
export const getKategoriUkuranList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/kategori_ukuran`, { params });
export const getKategoriUkuran = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/kategori_ukuran/${params}`);
export const postKategoriUkuran = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/kategori_ukuran`, params);
export const putKategoriUkuran = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/kategori_ukuran/${params.kategori_ukuran_id}`, params);
export const deleteKategoriUkuran = (params) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/kategori_ukuran/${params.kategori_ukuran_id}`);

// Ukuran
export const getUkuranList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/ukuran`, { params });
export const getUkuran = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/ukuran/${params}`);
export const postUkuran = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/ukuran`, params);
export const putUkuran = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/ukuran/${params.ukuran_id}`, params);
export const deleteUkuran = (params) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/ukuran/${params.ukuran_id}`);

// Produk
export const getProdukList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/produk`, { params });
export const getProduk = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/produk/${params}`);
export const postProduk = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/produk`, params);
export const putProduk = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/produk/${params.produk_id}`, params);
export const deleteProduk = (params) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/produk/${params.produk_id}`);

// Kategori Harga
export const getKategoriHargaList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/kategori_harga`, { params });
export const getKategoriHarga = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/kategori_harga/${params}`);
export const postKategoriHarga = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/kategori_harga`, params);
export const putKategoriHarga = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/kategori_harga/${params.kategori_harga_id}`, params);
export const deleteKategoriHarga = (params) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/kategori_harga/${params.kategori_harga_id}`);

// Harga Produk
export const getHargaProdukList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/harga_produk`, { params });
export const getHargaProduk = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/harga_produk/${params}`);
export const postHargaProduk = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/harga_produk`, params);
export const putHargaProduk = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/harga_produk/${params.harga_produk_id}`, params);
export const deleteHargaProduk = (params) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/harga_produk/${params.harga_produk_id}`);

// Departemen
export const getDepartemenList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/departemen`, { params });
export const getDepartemen = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/departemen/${params}`);
export const postDepartemen = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/departemen`, params);
export const putDepartemen = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/departemen/${params.departemen_id}`, params);
export const deleteDepartemen = (params) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/departemen/${params.departemen_id}`);

// Account
export const getAccountList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/account`, { params });
export const getAccount = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/account/${params}`);
export const postAccount = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/account`, params);
export const putAccount = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/account/${params.account_id}`, params);
export const deleteAccount = (params) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/account/${params.account_id}`);

// Kategori Promo
export const getKategoriPromoList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/kategori_promo`, { params });
export const getKategoriPromo = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/kategori_promo/${params}`);
export const postKategoriPromo = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/kategori_promo`, params);
export const putKategoriPromo = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/kategori_promo/${params.kategori_promo_id}`, params);
export const deleteKategoriPromo = (params) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/kategori_promo/${params.kategori_promo_id}`);

// Cost Center
export const getCostCenterList = (params) => {
    const { head, ...paramsRest } = params;
    return axios.get(
        head ? `${REACT_APP_CORE_SOA_URL}/v1/cost_center/${params.head}/cost_center` : `${REACT_APP_CORE_SOA_URL}/v1/cost_center`,
        { params: paramsRest }
    );
};

export const getCostCenter = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/cost_center/${params}`);
export const postCostCenter = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/cost_center`, params);
export const putCostCenter = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/cost_center/${params.cost_center_id}`, params);
export const deleteCostCenter = (params) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/cost_center/${params.cost_center_id}`);

// Promo Plan
export const getPromoPlanList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/promo_plan`, { params });
export const getPromoPlan = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/promo_plan/${params}`);
export const postPromoPlan = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/promo_plan`, params);
export const putPromoPlan = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/promo_plan/${params.promo_plan_id}`, params);
export const deletePromoPlan = (params) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/promo_plan/${params.promo_plan_id}`);

// Promo Plan
export const getPromoPlanDetailList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/promo_plan_detail`, { params });
export const getPromoPlanDetail = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/promo_plan_detail/${params}`);

// Proposal
export const getProposalList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/proposal`, { params });
export const getProposal = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/proposal/${params}`);
export const postProposal = (params) => axios.post(`${REACT_APP_CORE_SOA_URL}/v1/proposal`, params);
export const putProposal = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/proposal/${params.proposal_id}`, params);
export const deleteProposal = (params) => axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/proposal/${params.proposal_id}`);

// Proposal Persetujuan
export const getProposalPersetujuanList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/proposal_persetujuan`, { params });
// Proposal Log
export const getProposalLogList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/proposal_log`, { params });
export const postProposalLog = (params) =>
    axios.post(`${REACT_APP_CORE_SOA_URL}/v1/proposal_persetujuan/${params.proposal_persetujuan_id}/proposal_log`, params);

// Post Promo
export const getProposalPostPromoList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/proposal_post_promo`, { params });
export const getProposalPostPromo = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/proposal_post_promo/${params}`);
export const postProposalPostPromo = (params) =>
    axios.post(`${REACT_APP_CORE_SOA_URL}/v1/proposal/${params.proposal_id}/proposal_post_promo`, params);
export const putProposalPostPromo = (params) =>
    axios.put(`${REACT_APP_CORE_SOA_URL}/v1/proposal_post_promo/${params.proposal_post_promo_id}`, params);
export const deleteProposalPostPromo = (params) =>
    axios.delete(`${REACT_APP_CORE_SOA_URL}/v1/proposal_post_promo/${params.proposal_post_promo_id}`);

// Proposal Klaim
export const putProposalKlaim = (params) => axios.put(`${REACT_APP_CORE_SOA_URL}/v1/proposal/${params.proposal_id}/proposal_klaim`, params);

// Proposal Auth
export const getProposalAuth = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/proposal_auth/${params}`);
export const postEmailApprovalProposalLog = (params) =>
    axios.post(`${REACT_APP_CORE_SOA_URL}/v1/proposal_auth/${params.auth_key}/proposal_log`, params);

// Report
export const getReportCategoryList = (params) => axios.get(`${REACT_APP_CORE_SOA_URL}/v1/report_category`, params);
export const getReportList = (params) =>
    axios.get(`${REACT_APP_CORE_SOA_URL}/v1/report_category/${params.report_category_id}/report`, params);
export const getReportParameter = (params) =>
    axios.get(`${REACT_APP_CORE_SOA_URL}/v1/report_category/${params.report_category_id}/report/${params.report_id}/parameter`, params);
export const postReportExecute = (params) =>
    axios.post(`${REACT_APP_CORE_SOA_URL}/v1/report_category/${params.report_category_id}/report/${params.report_id}/execute`, params);
export const postReportExecutePdf = (params) =>
    axios.post(`${REACT_APP_CORE_SOA_URL}/v1/report_category/${params.report_category_id}/report/${params.report_id}/execute/pdf`, params, {
        responseType: 'arraybuffer'
    });
export const postReportExecuteXlsx = (params) =>
    axios.post(
        `${REACT_APP_CORE_SOA_URL}/v1/report_category/${params.report_category_id}/report/${params.report_id}/execute/xlsx`,
        params,
        {
            responseType: 'arraybuffer'
        }
    );
