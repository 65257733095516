// action - state management
import * as actionTypes from './types';

// WARNING /!\
// This states should follow available props on DataGrid Component
const baseGridState = {
    page: 0,
    pageSize: 5,
    rowCount: 0,
    keyword: '',
    search: {},
    sortModel: [],
    rows: [],
    loading: false
};

export const initialState = {
    backendAccess: { ...baseGridState },
    costCenter: { ...baseGridState },

    kategoriPromo: { ...baseGridState },
    promoPlan: { ...baseGridState },

    account: { ...baseGridState },
    area: { ...baseGridState },
    brand: { ...baseGridState },
    subBrand: { ...baseGridState },
    departemen: { ...baseGridState },
    kategoriHarga: { ...baseGridState },
    kategoriUkuran: { ...baseGridState },
    ukuran: { ...baseGridState },
    rasa: { ...baseGridState },
    produk: { ...baseGridState },

    proposalPersetujuan: { ...baseGridState },
    proposal: { ...baseGridState, pageSize: 10 },
    proposalLog: { ...baseGridState },
    proposalPostPromo: { ...baseGridState }
};

// ==============================|| profile REDUCER ||============================== //

const gridReducer = (state = initialState, action) => {
    switch (action.type) {
        // Access
        case actionTypes.SET_GRID_BACKEND_ACCESS:
            return {
                ...state,
                backendAccess: action.payload
            };
        case actionTypes.SET_GRID_COST_CENTER:
            return {
                ...state,
                costCenter: action.payload
            };

        // Budget
        case actionTypes.SET_GRID_PROMO_PLAN:
            return {
                ...state,
                promoPlan: action.payload
            };
        case actionTypes.SET_GRID_KATEGORI_PROMO:
            return {
                ...state,
                kategoriPromo: action.payload
            };

        // Master
        case actionTypes.SET_GRID_ACCOUNT:
            return {
                ...state,
                account: action.payload
            };
        case actionTypes.SET_GRID_AREA:
            return {
                ...state,
                area: action.payload
            };
        case actionTypes.SET_GRID_BRAND:
            return {
                ...state,
                brand: action.payload
            };
        case actionTypes.SET_GRID_SUB_BRAND:
            return {
                ...state,
                subBrand: action.payload
            };
        case actionTypes.SET_GRID_DEPARTEMEN:
            return {
                ...state,
                departemen: action.payload
            };
        case actionTypes.SET_GRID_KATEGORI_HARGA:
            return {
                ...state,
                kategoriHarga: action.payload
            };
        case actionTypes.SET_GRID_KATEGORI_UKURAN:
            return {
                ...state,
                kategoriUkuran: action.payload
            };
        case actionTypes.SET_GRID_UKURAN:
            return {
                ...state,
                ukuran: action.payload
            };
        case actionTypes.SET_GRID_RASA:
            return {
                ...state,
                rasa: action.payload
            };
        case actionTypes.SET_GRID_PRODUK:
            return {
                ...state,
                produk: action.payload
            };

        // Proposal
        case actionTypes.SET_GRID_PROPOSAL_PERSETUJUAN:
            return {
                ...state,
                proposalPersetujuan: action.payload
            };
        case actionTypes.SET_GRID_PROPOSAL:
            return {
                ...state,
                proposal: action.payload
            };
        case actionTypes.SET_GRID_PROPOSAL_LOG:
            return {
                ...state,
                proposalLog: action.payload
            };
        case actionTypes.SET_GRID_PROPOSAL_POST_PROMO:
            return {
                ...state,
                proposalPostPromo: action.payload
            };

        default:
            return state;
    }
};

export default gridReducer;
