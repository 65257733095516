export const SET_GRID_BACKEND_ACCESS = 'SET_GRID_BACKEND_ACCESS';
export const SET_GRID_COST_CENTER = 'SET_GRID_COST_CENTER';

export const SET_GRID_KATEGORI_PROMO = 'SET_GRID_KATEGORI_PROMO';
export const SET_GRID_PROMO_PLAN = 'SET_GRID_PROMO_PLAN';

export const SET_GRID_ACCOUNT = 'SET_GRID_ACCOUNT';
export const SET_GRID_AREA = 'SET_GRID_AREA';
export const SET_GRID_BRAND = 'SET_GRID_BRAND';
export const SET_GRID_SUB_BRAND = 'SET_GRID_SUB_BRAND';
export const SET_GRID_DEPARTEMEN = 'SET_GRID_DEPARTEMEN';
export const SET_GRID_KATEGORI_HARGA = 'SET_GRID_KATEGORI_HARGA';
export const SET_GRID_KATEGORI_UKURAN = 'SET_GRID_KATEGORI_UKURAN';
export const SET_GRID_RASA = 'SET_GRID_RASA';
export const SET_GRID_UKURAN = 'SET_GRID_UKURAN';
export const SET_GRID_PRODUK = 'SET_GRID_PRODUK';

export const SET_GRID_PROPOSAL_PERSETUJUAN = 'SET_GRID_PROPOSAL_PERSETUJUAN';
export const SET_GRID_PROPOSAL = 'SET_GRID_PROPOSAL';
export const SET_GRID_PROPOSAL_LOG = 'SET_GRID_PROPOSAL_LOG';
export const SET_GRID_PROPOSAL_POST_PROMO = 'SET_GRID_PROPOSAL_POST_PROMO';
