// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill, IconMailbox, IconNotebook, IconLicense, IconArchive } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconMailbox,
    IconNotebook,
    IconLicense,
    IconArchive
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const proposal = {
    id: 'proposal',
    title: 'Proposal',
    type: 'group',
    children: [
        {
            id: 'proposal-inbox',
            title: 'Inbox Proposal',
            type: 'item',
            url: '/proposal/proposal-persetujuan/list',
            icon: icons.IconMailbox,
            breadcrumbs: false,
            roles: ['MARKETING']
        },
        {
            id: 'proposal-list',
            title: 'Daftar Proposal',
            type: 'item',
            url: '/proposal/proposal/list',
            icon: icons.IconNotebook,
            breadcrumbs: false
        },
        {
            id: 'proposal-archive',
            title: 'Arsip Proposal',
            type: 'item',
            url: '/proposal/proposal-archive/list',
            icon: icons.IconArchive,
            breadcrumbs: false
        },
        {
            id: 'proposal-log',
            title: 'Riwayat Persetujuan',
            type: 'item',
            url: '/proposal/proposal-log/list',
            icon: icons.IconLicense,
            breadcrumbs: false,
            roles: ['MARKETING']
        }
    ]
};

export default proposal;
