// assets
import { IconFileAnalytics } from '@tabler/icons';

// constant
const icons = { IconFileAnalytics };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const report = {
    id: 'system-report-switchboard',
    type: 'group',
    children: [
        {
            id: 'sample-page',
            title: 'Pusat Laporan',
            type: 'item',
            url: '/system/report-switchboard',
            icon: icons.IconFileAnalytics,
            breadcrumbs: false
        }
    ]
};

export default report;
