// action - state management
import * as actionTypes from './types';

export const initialState = {
    dashboard: null,
    chart: null
};

// ==============================|| profile REDUCER ||============================== //

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_DASHBOARD:
            return {
                ...state,
                dashboard: action.payload
            };
        case actionTypes.SET_DASHBOARD_CHART:
            return {
                ...state,
                chart: action.payload
            };
        default:
            return state;
    }
};

export default dashboardReducer;
