const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '/',
    defaultPath: '/dashboard',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    listViewGridBaseProps: {
        pagination: true,
        paginationMode: 'server',
        rowsPerPageOptions: [5, 10, 20, 50, 100],
        filterMode: 'server',
        sortingMode: 'server',
        autoHeight: true,
        checkboxSelection: true,
        disableSelectionOnClick: true,
        disableColumnMenu: true,
        // PFI Customization
        /*
        columnHeaderHeight: 32,
        rowHeight: 32
        */
        density: 'compact',
        sx: {
            m: 0,
            p: 0,
            fontSize: 12
        }
    },
    baseTextFieldProps: {
        fullWidth: true,
        variant: 'outlined',
        margin: 'dense',
        type: 'text'
    }
};

export default config;
