import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

import { SnackbarProvider } from 'notistack';

import { Routes, Route, Link, useNavigate, useLocation, Navigate, Outlet } from 'react-router-dom';

// routing
import PublicRoutes from 'routes/PublicRoutes';
import PrivateRoutes from 'routes/PrivateRoutes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import ResponsiveDialog from 'ui-component/extended/ResponsiveDialog';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    // Please wait until storage is loaded then render
    // the routes
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <SnackbarProvider maxSnack={3}>
                    <CssBaseline />
                    <NavigationScroll>
                        <PublicRoutes />
                        <PrivateRoutes />
                        <ResponsiveDialog />
                    </NavigationScroll>
                </SnackbarProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
