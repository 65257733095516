import numeral from 'numeral';
import format from 'date-fns/format';

// Empty Object
export const isEmpty = (obj) => !obj || Object.keys(obj).length === 0;

/* -- Indonesian Rupiah Style -- */
numeral.register('locale', 'id', {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    abbreviations: {
        thousand: 'rb',
        million: 'jt',
        billion: 'M',
        trillion: 'T'
    },
    ordinal(number) {
        return number === 1 ? 'er' : 'ème';
    },
    currency: {
        symbol: 'Rp'
    }
});
numeral.locale('id');

export const IDRFormatter = (number) => numeral(Number(number)).format('$ 0,0');
export const IDRFormatterWithoutCurrency = (number) => numeral(Number(number)).format('0,0');
export const IDRFormatToNumber = (text) => numeral(text).value();
export const IDRFormatAbbreviation = (text) => numeral(Number(text)).format('0.0a');

export const objIsEmpty = (obj) => {
    if (typeof obj === 'object' && !(obj instanceof Array)) {
        if (Object.keys(obj).length === 0) {
            return true;
        }
    }
    return false;
};

export const addDays = (date, days) => {
    const copy = new Date(Number(date));

    copy.setDate(date.getDate() + days);

    return copy;
};

// Array Manipulation
export const arrEvery = (arr, key, value) => arr?.every((item) => item?.[key] === value);

export const arrSearch = (arr, key, query) => {
    const transQuery = query.toLowerCase();

    const filtered = arr.filter((item) => {
        const transValue = item[key].toLowerCase();

        return transValue.includes(transQuery);
    });

    return filtered;
};

/* -- Validators -- */
export const isEmail = (value) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(value);
export const isPhone = (value) => /^(^\+62\s?|^0)(\d{3,4}-?){2}\d{3,4}$/g.test(value);
export const onlyLetters = (value) => /^[a-zA-Z\s]+$/.test(value);
export const passwordValidation = (value) => /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(value);
export const isNoNumber = (value) => /^([^0-9]*)$/.test(value);
export const isNumber = (value) => /^\d+$/.test(value);
export const isUrl = (value) => /[-a-zA-Z0-9@:%.+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%+.~#?&=]*)/.test(value);

/* -- Image Rewizing -- */
export const ImgResizeDimension = (image) => {
    const maxWidth = 1600;
    const maxHeight = 1024;

    const originalWidth = image.width;
    const originalHeight = image.height;
    let resizeWidth = 0;
    let resizeHeight = 0;

    if (originalWidth > maxWidth && originalHeight > maxHeight) {
        resizeWidth = Math.floor(maxHeight * (originalWidth / originalHeight));
        resizeHeight = maxHeight;
    } else if (originalWidth > maxWidth) {
        resizeWidth = maxWidth;
        resizeHeight = Math.floor(maxWidth * (originalHeight / originalWidth));
    } else if (originalHeight > maxHeight) {
        resizeWidth = Math.floor(maxHeight * (originalWidth / originalHeight));
        resizeHeight = maxHeight;
    } else {
        resizeWidth = originalWidth;
        resizeHeight = originalHeight;
    }

    return {
        width: resizeWidth,
        height: resizeHeight
    };
};

export const capFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const initialName = (name, maxInitial) => {
    if (typeof maxInitial === 'undefined') {
        maxInitial = 2;
    }
    return name
        .split(' ')
        .slice(0, maxInitial - 1)
        .map((word) => word.charAt(0))
        .join('');
};

export const transformServerError = (payload) => {
    const serverErrors = {};
    Object.values(payload).forEach((row) => {
        let pointer = serverErrors;
        const part = row.field.split('.');
        Object.values(part).forEach((path, idx) => {
            if (idx === part.length - 1) {
                // Last part, execute assignment
                pointer[path] = row.message;
            } else {
                if (!pointer[path]) {
                    pointer[path] = {};
                }

                pointer = pointer[path];
            }
        });
    });

    return serverErrors;
};

export const trim = (str, ch) => {
    let start = 0;
    let end = str.length;

    while (start < end && str[start] === ch) start += 1;

    while (end > start && str[end - 1] === ch) end -= 1;

    return start > 0 || end < str.length ? str.substring(start, end) : str;
};

export const getFileNameFromDisposition = (disposition) => {
    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/i;
    const asciiFilenameRegex = /filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

    let fileName = null;
    if (utf8FilenameRegex.test(disposition)) {
        fileName = decodeURIComponent(utf8FilenameRegex.exec(disposition)[1]);
    } else {
        const matches = asciiFilenameRegex.exec(disposition);
        if (matches != null && matches[2]) {
            fileName = matches[2];
        }
    }
    return fileName;
};

export const formatDate = (input) => format(Date.parse(input.replace(' ', 'T')), 'dd/LL/yyyy');

export const formatDateTime = (input) => format(Date.parse(input.replace(' ', 'T')), 'dd/LL/yyyy HH:mm');
